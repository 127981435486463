import React, { useState, useEffect } from 'react';
import { message } from 'antd'; // Assuming you're using Ant Design for message popups

export default function CustomForm({ status, formMessage, onValidated }) {
    const [email, setEmail] = useState("");

    useEffect(() => {
        if(status === "success"){
            message.destroy();
            message.success("Subscribed successfully");
        } else if(status === "error"){
            message.destroy();
            message.error("Unable to subscribe. Please try again later.");
        } else if(status === "sending"){
            message.loading("Sending...");
        }
    }, [status]);

    const handleSubmit = (e) => {
        e.preventDefault();
        email && email.indexOf("@") > -1 && onValidated({ EMAIL: email });
    };

    return (
        <div className="max-w-md mx-auto mt-10 bg-white shadow-lg rounded-lg overflow-hidden p-5">
           
            <form onSubmit={handleSubmit} className="flex flex-col space-y-4">
                <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Your email"
                    required
                    className="px-4 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
                />
                <button type="submit" className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded cursor-pointer transition-colors">
                    Subscribe
                </button>
            </form>
            {status === "sending" && <div className="text-center mt-2">Sending...</div>}
            {status === "error" && (
                <div className="text-red-500 text-center mt-2" dangerouslySetInnerHTML={{ __html: formMessage }} />
            )}
            {status === "success" && <div className="text-green-500 text-center mt-2">Thanks for Subscribing!</div>}
        </div>
    );
}