import React, { useState } from "react";
import {
  Input,
  Tag,
  Modal,
  Form,
  Steps,
  Select,
  InputNumber,
  message,
} from "antd";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import AiRecipe from "./AiRecipe";
import { Helmet } from "react-helmet";
const { Step } = Steps;
const { Option } = Select;

const categories = [
  "Gastritis-Friendly",
  "IBS-Friendly",
  "GERD-Friendly",
  "Barretts-Esophagus-Friendly",
];

const ethnicStyles = [
  "North-American",
  "South-American",
  "Chinese",
  "Italian",
  "Mexican",
  "Indian",
  "Japanese",
  "East-Asian",
  "SouthEast-Asian",
  "West-African",
  "East-African",
  "Central-African",
  "Middle-Eastern",
  "Caribbean",
  "Western-European",
  "Eastern-European",
  "Russian",
  "USA",
];

const FoodSearch = () => {
  const [text, setText] = useState("");
  const [selectedTags, setSelectedTags] = useState([]);
  const navigate = useNavigate();
  const [aiRecipe, setAiRecipe] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [loadingOpenAiResponse, setLoadingOpenAiResponse] = useState(false);
  const [currentSection, setCurrentSection] = useState(null);
  const [formData, setFormData] = useState({
    ingredients: [],
    condition: "",
    ethnicStyle: "",
    cookingTime: null,
  });
  const TIMEOUT_DURATION = 30000; // 30 seconds
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    name: "GERD Friendly Recipes",
    description:
      "Find and create GERD-friendly recipes for acid reflux management. Community-contributed and AI-generated recipes available.",
    url: "https://yourwebsite.com",
    potentialAction: {
      "@type": "SearchAction",
      target: "https://yourwebsite.com/search?q={search_term_string}",
      "query-input": "required name=search_term_string",
    },
  };
  const handleTagClick = (tag) => {
    const updatedSelectedTags = selectedTags.includes(tag)
      ? selectedTags.filter((t) => t !== tag)
      : [...selectedTags, tag];
    setSelectedTags(updatedSelectedTags);
  };

  const searchRecipe = () => {
    if (text === "" && selectedTags.length === 0) {
      message.info("Please enter text or select a tag");
      return;
    }
    const categoryQuery = selectedTags.join(" OR category: ");
    navigate(
      `/search?search_query=${text}&calorieTotal=${0} TO ${50000}&category=${categoryQuery}`
    );
    window.location.reload();
  };

  const steps = [
    {
      title: "Ingredients",
      content: (
        <>
          <Form.Item
            label="Ingredients"
            name="ingredients"
            rules={[
              {
                required: true,
                message: "Please input at least one ingredient",
              },
            ]}
          >
            <Select
              mode="tags"
              style={{ width: "100%" }}
              placeholder="Enter ingredients and press enter or comma"
              value={formData.ingredients}
              onChange={(value) =>
                setFormData({ ...formData, ingredients: value })
              }
              tokenSeparators={[","]}
            />
          </Form.Item>
          <div style={{ marginTop: 8, color: "gray" }}>
            Please separate each ingredient by pressing "Enter" or typing a
            comma.
          </div>
        </>
      ),
    },
    {
      title: "Condition",
      content: (
        <Form.Item
          label="Gastritis Condition"
          name="condition"
          rules={[{ required: true, message: "Please select a condition" }]}
        >
          <Select
            placeholder="Select a condition"
            value={formData.condition}
            onChange={(value) => setFormData({ ...formData, condition: value })}
          >
            {categories.map((condition) => (
              <Option key={condition} value={condition}>
                {condition}
              </Option>
            ))}
          </Select>
        </Form.Item>
      ),
    },
    {
      title: "Style",
      content: (
        <Form.Item
          label="Ethnic Style"
          name="ethnicStyle"
          rules={[{ required: true, message: "Please select an ethnic style" }]}
        >
          <Select
            placeholder="Select an ethnic style"
            value={formData.ethnicStyle}
            onChange={(value) =>
              setFormData({ ...formData, ethnicStyle: value })
            }
          >
            {ethnicStyles.map((style) => (
              <Option key={style} value={style}>
                {style}
              </Option>
            ))}
          </Select>
        </Form.Item>
      ),
    },
    {
      title: "Cooking Time",
      content: (
        <Form.Item
          label="Preferred Cooking Time (minutes)"
          name="cookingTime"
          rules={[{ required: true, message: "Please input cooking time" }]}
        >
          <InputNumber
            min={1}
            max={500}
            placeholder="Enter cooking time in minutes"
            value={formData.cookingTime}
            onChange={(value) =>
              setFormData({ ...formData, cookingTime: value })
            }
            style={{ width: "100%" }}
          />
        </Form.Item>
      ),
    },
  ];

  const next = () => {
    setCurrentStep(currentStep + 1);
  };

  const prev = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleSubmit = async () => {
    setLoadingOpenAiResponse(true); // Show loading indicator
    message.loading("Please don't click away. Processing your request.", 0);
    try {
      const prompt = `Please create a ${
        formData.condition
      } recipe using the following ingredients: ${formData.ingredients.join(
        ", "
      )}. 
      The recipe should be of ${formData.ethnicStyle} style and should take ${
        formData.cookingTime
      } minutes to prepare/cook. 
      Please format the response as follows:
      
  **TITLE: [Recipe Title]**
      
  **INGREDIENTS:**
      • [List each ingredient with quantity]
      
  **ESTIMATED TIME:**
      • Prep Time: [X] minutes
      • Cook Time: [Y] minutes
      • Total Time: [Z] minutes
      
**INSTRUCTIONS:**
      1. [Step-by-step instructions]
      
**NUTRITION INFORMATION:**
      • Calories:
      • Protein:
      • Carbs:
      • Fat:
      
**DIETARY NOTES:**
      [Any relevant dietary information or substitutions] 
    [Notes]
    Ensure that the section headings are surrounded by double asterisks (e.g, **TITLE: ... ***)`;
      // Create a cancel token for timeout
      const source = axios.CancelToken.source();
      const timeoutId = setTimeout(() => {
        source.cancel("Request timed out");
      }, TIMEOUT_DURATION);
      // Make a POST request to your Firebase function
      const response = await axios
        .post(
          "https://us-central1-gingerrmarket.cloudfunctions.net/generateChatResponse", // replace with your actual URL
          {
            message: prompt, // Send the generated prompt to Firebase
          },
          {
            cancelToken: source.token,
            headers: {
              "Content-Type": "application/json",
            },
            // Retry configuration
            retry: 3,
            retryDelay: (retryCount) => retryCount * 1000, // Progressive delay
          }
        )
        .finally(() => {
          clearTimeout(timeoutId);
        });

      // Parse and validate response
      if (!response.data) {
        message.error("Recieved empty response from server");
        throw new Error("Recieved empty response from server.");
      }

      // AI response
      setAiRecipe(response.data);
      message.destroy();
      message.success(
        "Your personal AI recipe was generated! Congrats and enjoy!"
      );
      setCurrentSection("ingredients");
    } catch (error) {
      // Check for rate limit error
      if (error.response?.status === 429) {
        message.error("Rate limit reached. Please try again in a few minutes.");
      }
      // Check for timeout
      else if (axios.isCancel(error)) {
        message.error("Request timed out. Please try again.");
      }
      // Check for network errors
      else if (error.code === "ERR_NETWORK") {
        message.error(
          "Network error. Please check your connection and try again."
        );
      }
      // Check for server errors
      else if (error.response?.status >= 500) {
        message.error("Server error. Please try again later.");
      }
      // Handle other types of errors
      else {
        message.error(
          "An error occurred while generating your recipe. Please try again."
        );
      }
    } finally {
      setLoadingOpenAiResponse(false); // Hide loading indicator
      setIsModalVisible(false); // Close modal
      message.destroy();
    }
  };

  return (
    <>
      <Helmet>
        <title>
          GERD Friendly Recipes | Find & Create Acid Reflux Safe Dishes
        </title>
        <meta
          name="description"
          content="Discover GERD-friendly recipes shared by our community or create your own using AI. Find acid reflux safe dishes and cooking tips for better digestive health."
        />
        <meta
          name="keywords"
          content="GERD recipes, acid reflux friendly food, GERD diet, digestive health recipes, AI recipe generator"
        />
        <meta
          property="og:title"
          content="GERD Friendly Recipes | Safe & Delicious Dishes"
        />
        <meta
          property="og:description"
          content="Find and create GERD-friendly recipes that are safe for acid reflux. Join our community or use AI to generate custom recipes."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://yourwebsite.com" />
        <meta
          property="og:image"
          content="https://yourwebsite.com/og-image.jpg"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="GERD Friendly Recipes | Safe & Delicious Dishes"
        />
        <meta
          name="twitter:description"
          content="Find and create GERD-friendly recipes that are safe for acid reflux. Community recipes and AI-powered recipe generation."
        />
      </Helmet>
      <div className="min-h-screen bg-gradient-to-b from-white to-gray-50">
        <div className="max-w-6xl mx-auto px-4 py-12">
          {/* Hero Section */}
          <header className="text-center space-y-2 mb-4">
            <h1 className="text-4xl md:text-5xl lg:text-6xl pb-6 font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-purple-600">
              GERD Friendly Recipes for Better Health
            </h1>
            <p className="text-gray-600 text-lg md:text-xl max-w-2xl mx-auto">
              Discover gerd-friendly recipes from our community or create your
              own using AI assistance
            </p>
          </header>

          {/* Search Section */}
          <section
            aria-label="Recipe Search"
            className="bg-white rounded-2xl shadow-lg p-6 md:p-8 max-w-2xl mx-auto"
          >
            <div className="bg-white rounded-2xl shadow-lg p-6 md:p-8 max-w-2xl mx-auto">
              <div className="space-y-6">
                <label htmlFor="recipe-search" className="sr-only">
                  Search for dishes
                </label>

                <Input
                  type="text"
                  placeholder="Search for dishes..."
                  className="w-full h-12 px-4 rounded-lg border-2 border-gray-200 focus:border-blue-500 transition-colors"
                  onChange={(e) => setText(e.target.value)}
                />

                <div className="flex flex-wrap gap-2 justify-center">
                  {categories.map((tag) => (
                    <Tag.CheckableTag
                      key={tag}
                      checked={selectedTags.includes(tag)}
                      onChange={() => handleTagClick(tag)}
                      className="px-4 py-2 rounded-full transition-colors"
                    >
                      {tag}
                    </Tag.CheckableTag>
                  ))}
                </div>

                <button
                  onClick={searchRecipe}
                  className="w-full bg-blue-600 hover:bg-blue-700 text-white font-semibold py-3 px-6 rounded-lg transition-colors duration-200 transform hover:scale-105"
                >
                  Search Recipes
                </button>
              </div>
            </div>
          </section>

          {/* Action Cards with semantic HTML */}
          <section
            aria-label="Recipe Actions"
            className="grid md:grid-cols-2 gap-6 mt-12 max-w-4xl mx-auto"
          >
            <article className="bg-white rounded-xl shadow-md p-6 hover:shadow-lg transition-shadow">
              <h2 className="text-xl font-semibold mb-4 text-gray-800">
                Contribute to the GERD Recipe Community
              </h2>
              <p className="text-gray-600 mb-4">
                Share your successful GERD-friendly recipes and help others
                manage their acid reflux through diet
              </p>
              <button
                onClick={() => navigate("/new_post")}
                className="w-full bg-green-600 hover:bg-green-700 text-white font-semibold py-3 px-6 rounded-lg transition-colors duration-200"
                aria-label="Upload your GERD-friendly recipe"
              >
                Upload Your Recipe
              </button>
            </article>

            <article className="bg-white rounded-xl shadow-md p-6 hover:shadow-lg transition-shadow">
              <h2 className="text-xl font-semibold mb-4 text-gray-800">
                Create GERD-Safe Recipes with AI
              </h2>
              <p className="text-gray-600 mb-4">
                Let our AI assistant help you create personalized GERD-friendly
                recipes based on your triggers and preferences
              </p>
              <button
                onClick={() => setIsModalVisible(true)}
                className="w-full bg-purple-600 hover:bg-purple-700 text-white font-semibold py-3 px-6 rounded-lg transition-colors duration-200"
                aria-label="Create AI-generated GERD-friendly recipe"
              >
                Create Recipe with AI
              </button>
            </article>
          </section>
          {aiRecipe && (
            <AiRecipe scrollToSection={currentSection} recipeText={aiRecipe} />
          )}

          {/* Modal */}
          <Modal
            title={
              <h3 className="text-2xl font-semibold text-gray-800">
                Craft a Recipe using AI
              </h3>
            }
            visible={isModalVisible}
            onCancel={() => setIsModalVisible(false)}
            footer={null}
            width={600}
            className="rounded-lg"
          >
            <Form layout="vertical" className="p-4">
              <Steps current={currentStep}>
                {steps.map((item) => (
                  <Step key={item.title} title={item.title} />
                ))}
              </Steps>

              <div className="mt-8 space-y-4">
                {steps[currentStep].content}

                <div className="flex justify-end space-x-4 mt-8">
                  {currentStep > 0 && (
                    <button
                      onClick={prev}
                      className="px-6 py-2 bg-gray-200 text-gray-700 rounded-lg hover:bg-gray-300"
                    >
                      Previous
                    </button>
                  )}

                  {currentStep < steps.length - 1 && (
                    <button
                      onClick={next}
                      disabled={
                        currentStep === 0 && formData.ingredients.length === 0
                      }
                      className={`px-6 py-2 rounded-lg ${
                        currentStep === 0 && formData.ingredients.length === 0
                          ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                          : "bg-green-600 text-white hover:bg-green-700"
                      }`}
                    >
                      Next
                    </button>
                  )}

                  {currentStep === steps.length - 1 && (
                    <button
                      onClick={handleSubmit}
                      disabled={loadingOpenAiResponse}
                      className={`px-6 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 ${
                        loadingOpenAiResponse
                          ? "opacity-50 cursor-not-allowed"
                          : ""
                      }`}
                    >
                      {loadingOpenAiResponse ? "Generating..." : "Submit"}
                    </button>
                  )}
                </div>
              </div>
            </Form>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default FoodSearch;
