import React, { useRef, useEffect } from "react";
import {
  Card,
  Typography,
  Statistic,
  List,
  Divider,
  Row,
  Col,
  message,
} from "antd";
import {
  ClockCircleOutlined,
  FireOutlined,
  HeartOutlined,
} from "@ant-design/icons";

const { Title, Text, Paragraph } = Typography;

const AiRecipe = ({ recipeText, scrollToSection }) => {
  // Refs for each section
  const ingredientsRef = useRef(null);
  const instructionsRef = useRef(null);
  const nutritionRef = useRef(null);
  const dietaryNotesRef = useRef(null);

  // Effect to handle scrolling
  useEffect(() => {
    if (scrollToSection) {
      const refs = {
        ingredients: ingredientsRef,
        instructions: instructionsRef,
        nutrition: nutritionRef,
        dietaryNotes: dietaryNotesRef,
      };

      const targetRef = refs[scrollToSection];
      if (targetRef?.current) {
        targetRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest'
        });
      }
    }
  }, [scrollToSection]);

  // Parser function to extract sections from the markdown text
  const parseRecipe = (text) => {
    const sections = {};
    try {
      // Extract title
      const titleMatch = text.match(/\*\*TITLE:\s*(.*?)\*\*/);
      sections.title = titleMatch ? titleMatch[1].trim() : "";

      // Extract ingredients
      const ingredientsMatch = text.match(
        /\*\*INGREDIENTS:\*\*(.*?)\*\*ESTIMATED TIME:/s
      );
      sections.ingredients = ingredientsMatch
        ? ingredientsMatch[1]
            .split(/-\s+/)
            .map((item) => item.trim())
            .filter((item) => item.length > 0)
        : [];

      // Extract time
      const timeMatch = text.match(
        /\*\*ESTIMATED TIME:\*\*(.*?)\*\*INSTRUCTIONS:/s
      );
      if (timeMatch) {
        const timeText = timeMatch[1];
        sections.prepTime = timeText.match(/Prep Time:\s*(\d+)/)?.[1] || "0";
        sections.cookTime = timeText.match(/Cook Time:\s*(\d+)/)?.[1] || "0";
        sections.totalTime = timeText.match(/Total Time:\s*(\d+)/)?.[1] || "0";
      }

      // Extract instructions
      const instructionsMatch = text.match(
        /\*\*INSTRUCTIONS:\*\*(.*?)\*\*NUTRITION INFORMATION:/s
      );
      sections.instructions = instructionsMatch
        ? instructionsMatch[1]
            .split(/\d+\./)
            .map((item) => item.trim())
            .filter((item) => item.length > 0)
        : [];

      // Extract nutrition
      const nutritionMatch = text.match(
        /\*\*NUTRITION INFORMATION:\*\*(.*?)\*\*DIETARY NOTES:/s
      );
      if (nutritionMatch) {
        sections.nutrition = {};
        const nutritionText = nutritionMatch[1];
        const calories = nutritionText
          .match(/Calories:\s*([^\n]+)/)?.[1]
          .trim();
        const protein = nutritionText.match(/Protein:\s*([^\n]+)/)?.[1].trim();
        const carbs = nutritionText.match(/Carbs:\s*([^\n]+)/)?.[1].trim();
        const fat = nutritionText.match(/Fat:\s*([^\n]+)/)?.[1].trim();
        sections.nutrition = { calories, protein, carbs, fat };
      }

      // Extract dietary notes
      const dietaryMatch = text.match(/\*\*DIETARY NOTES:\*\*(.*)/s);
      sections.dietaryNotes = dietaryMatch ? dietaryMatch[1].trim() : "";

      return sections;
    } catch (error) {
      message.error(
        "Something went wrong while parsing response from AI. Please try again."
      );
    }
  };

  const recipe = parseRecipe(recipeText);

  return (
    <div className="mt-8 w-full max-w-2xl mx-auto">
      <h1 className="text-2xl md:text-3xl font-bold text-center mb-6">
        AI Generated Recipe
      </h1>
      <Card className="shadow-lg">
        <Title level={2} className="m-0 text-center text-purple-600">
          {recipe.title}
        </Title>

        {/* Time Statistics */}
        <Row gutter={16} className="my-6">
          <Col span={8}>
            <Statistic
              title="Prep Time"
              value={`${recipe.prepTime} min`}
              prefix={<ClockCircleOutlined className="text-purple-500" />}
            />
          </Col>
          <Col span={8}>
            <Statistic
              title="Cook Time"
              value={`${recipe.cookTime} min`}
              prefix={<FireOutlined className="text-purple-500" />}
            />
          </Col>
          <Col span={8}>
            <Statistic
              title="Total Time"
              value={`${recipe.totalTime} min`}
              prefix={<ClockCircleOutlined className="text-purple-500" />}
            />
          </Col>
        </Row>

        <div ref={ingredientsRef}>
          <Divider orientation="left">
            <Text strong className="text-lg">
              Ingredients
            </Text>
          </Divider>

          <List
            dataSource={recipe.ingredients}
            renderItem={(item) => (
              <List.Item style={{padding: '0'}}>
                <Text>• {item}</Text>
              </List.Item>
            )}
            itemLayout="vertical"
          />
        </div>

        <div ref={instructionsRef}>
          <Divider orientation="left">
            <Text strong className="text-lg">
              Instructions
            </Text>
          </Divider>

          <List
            dataSource={recipe.instructions}
            renderItem={(item, index) => (
              <List.Item>
                <Text>{`${index + 1}. ${item}`}</Text>
              </List.Item>
            )}
          />
        </div>

        <div ref={nutritionRef}>
          <Divider orientation="left">
            <Text strong className="text-lg">
              <HeartOutlined className="mr-2 text-purple-500" />
              Nutrition Information
            </Text>
          </Divider>

          <Card className="bg-purple-50">
            <Row gutter={16}>
              {Object.entries(recipe.nutrition).map(([key, value]) => (
                <Col span={6} key={key}>
                  <Statistic
                    title={key.charAt(0).toUpperCase() + key.slice(1)}
                    value={value}
                    className="text-center"
                  />
                </Col>
              ))}
            </Row>
          </Card>
        </div>

        {recipe.dietaryNotes && (
          <div ref={dietaryNotesRef}>
            <Divider orientation="left">
              <Text strong className="text-lg">
                Dietary Notes
              </Text>
            </Divider>
            <Paragraph>{recipe.dietaryNotes}</Paragraph>
          </div>
        )}
      </Card>
    </div>
  );
};

export default AiRecipe;